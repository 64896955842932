<!--登录页面-->
<template>
	<transition name="component-fade" mode="out-in">
		<component :is="which_to_show" @componentChange="changeHandle"></component> 
	</transition>
</template>
<script>
import Login from './components/Login'
import Register from './components/Register'
import FindPwd from './components/FindPwd'
import EditPwd from './components/EditPwd'
import eventBus from '../../utils/eventBus.js'
export default {
	components: {
		Login,
		Register,
		FindPwd,
		EditPwd
	},
	data() {
		return {
			which_to_show: Login
		}
	},
	created() {
		// 机构注册成功之后返回登录页
		this.$eventBus.$on('backToLogin', (val) => {
			this.which_to_show = Login
		}),
		// 首次登录强制修改密码
		eventBus.$on('toEditPwd', (val) => {
			this.which_to_show = EditPwd
		})
	},
	methods: {
		changeHandle(component) {
			if (component === 'register') {
				this.which_to_show = Register
			} else if (component === 'login') {
				this.which_to_show = Login
			} else if (component === 'findPwd') {
				this.which_to_show = FindPwd
			}
		}
	}
}
</script>
<style scoped>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active for below version 2.1.8 */ {
  opacity: 0;
}
</style>
