<!--首次登录强制修改密码-->
<template>
  <div class="about-login-form-wrap">
    <header class="title-header clearfix">
      <h3 class="fl">修改密码</h3>
      <!-- <el-button type="text" @click="toLoginHandle" class="fr">立即登录</el-button> -->
    </header>
  <el-form
    ref="editPwdForm"
    :model="formObj"
    :rules="rules"
    label-width="120px"
    class="editPwd-form">
    <el-form-item prop="oldPassword" label="旧密码">
      <el-input v-model="formObj.oldPassword" type="password" />
    </el-form-item>
    <el-form-item prop="newPassword" label="新密码">
      <el-input v-model="formObj.newPassword" type="password" />
    </el-form-item>
    <el-form-item prop="checkPass" label="确认密码">
      <el-input v-model="formObj.checkPass" type="password" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" :class="submitBtn" @click="submitForm">提交</el-button>
    </el-form-item>
  </el-form>
  </div>
	<!-- <el-form
		ref="editPwdForm"
		:model="formObj"
		:rules="rules"
		label-width="120px"
		class="editPwd-form">
		<el-form-item prop="oldPassword" label="旧密码">
			<el-input v-model="formObj.oldPassword" type="password" />
		</el-form-item>
		<el-form-item prop="newPassword" label="新密码">
			<el-input v-model="formObj.newPassword" type="password" />
		</el-form-item>
		<el-form-item prop="checkPass" label="确认密码">
			<el-input v-model="formObj.checkPass" type="password" />
		</el-form-item>
		<el-form-item>
			<el-button type="primary" class="first-step-btn editPwd-btn" @click="submitForm">提交</el-button>
		</el-form-item>
	</el-form> -->
</template>
<script>
import Cookies from "js-cookie"
import {
	enforceUpdatePwdApi
} from '@/api/login/login'
export default {
  computed: {
    submitBtn() {
      const { newPassword, oldPassword, checkPass } = this.formObj
      const len1 = newPassword.trim().length
      const len2 = oldPassword.trim().length
      const len3 = checkPass.trim().length
      if (len1 && len2 && len3 > 0) {
        return 'first-step-btn editPwd-btn form-btn'
      } else {
        return 'first-step-btn editPwd-btn form-btn disabled'
      }
    }
  },
	data() {
		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.formObj.newPassword) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}
		return {
			formObj: {
				newPassword: '',
				oldPassword: '',
				userName: '',
				checkPass: ''
			},
			rules: {
				oldPassword: [
					{ required: true, trigger: "blur", message: "旧密码不能为空" }
				],
				newPassword: [
					{ required: true, trigger: "blur", message: "新密码不能为空" }
				],
				checkPass: [
					{ required: true, trigger: "blur", message: "确认密码不能为空" },
					{ validator: validatePass2, trigger: 'blur' }
				]
			}
		}
	},
	methods: {
		submitForm(formName) {
			this.$refs['editPwdForm'].validate(async (valid) => {
				if (valid) {
					this.formObj.userName = Cookies.get("username")
					let res = await enforceUpdatePwdApi(this.formObj)
					if (res && res.code === 200) {
						this.$emit('componentChange', 'editPwdSucess')
					}
				} else {
					console.log('error submit!!');
					return false
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/login.scss';
/* .editPwd-container {
	.editPwd-header {
		height: 80px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #FFFFFF;
		box-shadow: 0px 4px 6px 0px rgba(189, 192, 199, 0.7);
		img {
			vertical-align: middle;
		}
		.header-text {
			display: inline-block;
			border-left: 2px solid #C8D9FA;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			margin-left: 10px;
			padding-left: 10px;
		}
	}
	.editPwd-main {
		position: relative;
		.editPwd-form {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 40%;
			::v-deep.editPwd-btn {
				width: 100%;
				background: linear-gradient(90deg, #00AAFF, #2B60F8);
				border-radius: 2px;
			}
		}
	}
	.editPwd-footer {
		height: 40px !important;
		line-height: 40px;
		text-align: center;
		background-color: #EBEFF7;
		font-size: 14px;
		font-weight: 400;
		color: #999999;
	}
} */
</style>
