var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "component-fade", mode: "out-in" } },
    [
      _c(_vm.which_to_show, {
        tag: "component",
        on: { componentChange: _vm.changeHandle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }