<template>
    <div class="login">
      <!-- <div class="loading_box" id="loading_box">
        <canvas id="login-loading"></canvas>
      </div> -->
      <div class="loginLeftTop">
        <img src="../../../assets/images/pdBox-login.png" alt=""/>
      </div>
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <!-- <h1 class="title" style="font-size: 26px;">PDBox-药数矩阵</h1>
        <h3 class="title" style="font-size: 14px;">Pharmaceutical Data Factory</h3> -->
        <h1 class="title" style="font-size: 26px;">药数矩阵</h1>
        <h3 class="title" style="font-size: 14px;color: #2B60F8;">PDBox</h3>
        <el-form-item prop="username" style="margin-top:30px;">
          <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="用户名/手机号">
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            show-password
            v-model="loginForm.password"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleLogin">
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item style="display: none">
          <el-input
            v-model="loginForm.code"
            auto-complete="off"
            placeholder="验证码"
            style="width: 63%"
            @keyup.enter.native="handleLogin">
            <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
          </el-input>
          <div class="login-code">
            <img :src="codeUrl" @click="getCode" class="login-code-img"/>
          </div>
        </el-form-item>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div :span="12">
            <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
          </div>
          <div :span="12">
            <el-button type="text" @click="forgetPas">忘记密码？</el-button>
          </div>
        </div>
        <el-form-item style="width:100%;">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            :class="submitBtn"
            @click.native.prevent="handleLogin">
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
        <el-form-item style="width:100%;">
          <!-- <el-tooltip placement="top" effect="light">
            <div slot="content">
              <p>若无账号，请先进行机构注册申请，注册成功后，</p>
              <p>账号将通过短信及电子邮箱发送给您！</p>
            </div>
            <el-link icon="el-icon-info" style="color: #2B60F8;" @click="registryHandle">机构注册</el-link>
          </el-tooltip> -->
          <el-popover
            ref="popover"
            placement="bottom"
            width="340"
            trigger="click">
            <div>
              <p>若无账号，请先进行机构注册申请，注册成功后，</p>
              <p>账号将通过短信及电子邮箱发送给您！</p>
            </div>
          </el-popover>
          <i class="el-icon-info" v-popover:popover style="color: #2B60F8;cursor: pointer;"></i>
          <el-link style="color: #2B60F8;margin-left: 10px;" @click="registryHandle">机构注册</el-link>
          <!-- <el-button v-popover:popover type="text">机构注册</el-button> -->
        </el-form-item>
      </el-form>
      <!--右侧显示二维码-->
      <section class="login-right-fixed">
        <div class="fixed-item app-logo" id="app-logo" @mouseenter="isShowApp = true" @mouseleave="isShowApp = false">
          <!-- <i class="el-icon-chat-dot-round icon-tag"></i> -->
          <svg-icon icon-class="app-logo" class="icon-tag" />
          <span>微信小程序</span>
          <el-collapse-transition>
            <div v-show="isShowApp" class="app-qrcode-wrap">
              <div class="transition-box" style="background: #fff;position:absolute;left: -120px;width: 120px;height: 120px;padding: 10px;top: 0;">
                <img
                  :src="opennessConfig.androidAppDownloadQrCode"
                  style="width: 80%;height: 80%;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);" />
                <!-- <p style="font-size: 12px;color: #333;">下载Android端app</p> -->
              </div>
            </div>
          </el-collapse-transition>
        </div>
        <div class="fixed-item" id="official-accounts">
          <!-- <i class="el-icon-chat-dot-round icon-tag"></i> -->
          <svg-icon icon-class="account-logo" class="icon-tag" />
          <span>公众号</span>
        </div>
        <div class="fixed-item" id="help-document">
          <!-- <i class="el-icon-chat-dot-round icon-tag"></i> -->
          <a data-v-7ed19c32="" href="/PDBox-药数矩阵.pdf" target="_blank" class="item">
            <svg-icon icon-class="document-logo" class="icon-tag" style="margin:0px auto 5px;"/>
            <span>帮助文档</span>
          </a>
        </div>
        <div class="fixed-item" id="contact-us" @click="toServiceIntro">
          <!-- <i class="el-icon-chat-dot-round icon-tag"></i> -->
          <!-- <svg-icon icon-class="contact-logo" class="icon-tag" />
          <span>联系我们</span> -->
          <svg-icon icon-class="service-intro" class="icon-tag" fill="#fff" />
          <span>服务介绍</span>
        </div>
      </section>
      <!--  底部  -->
      <div class="el-login-footer">
        <!-- <span>Copyright © 2018-2021 winning All Rights Reserved.</span> -->
        <div>
          <img src="../../../assets/images/icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
          <span style="padding-left:5px;">{{ opennessConfig.webSystemLatestVersion }}</span>
        </div>
        <div>
          <img src="../../../assets/images/beian-icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
          <a class="baNum" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620"
            style="display:inline-block;text-decoration:none;">
            <span style="padding-left:5px;">沪公网安备 31010602006620号</span>
          </a>
        </div>
        <div>
          <a href="https://beian.miit.gov.cn" target="_blank" style="padding: 0;" class="baNum">沪ICP备20018067号-2</a>
        </div>
        <div><span style="font-size: 18px;vertical-align: middle;">©</span>2022 上海卫心科技有限公司</div>
        <div>
          <!-- <a @click="goToLink" class="baNum" target="_blank">服务协议</a> -->
          <a href="/serviceAgreement.html" class="baNum" target="_blank">服务协议</a>
          <!-- <router-link class="baNum" tag="a" target="_blank" :to="{path:'serviceAgreement'}">服务协议</router-link> -->
        </div>
        <div>
          <a href="/privacyPolicy.html" class="baNum" target="_blank">隐私政策</a>
          <!-- <router-link class="baNum" tag="a" target="_blank" :to="{name:'PrivacyPolicy'}">隐私政策</router-link> -->
        </div>
      </div>
    </div>
  </template>
  <script>
  import { getCodeImg } from "@/api/login";
  import { encrypt, decrypt } from '@/utils/jsencrypt'
  import mapleParticles from '@/utils/particles.js'
  import { opennessConfigList } from "@/api/system/config";
  import Cookies from "js-cookie";
  export default {
    name: "Login",
    data() {
      return {
        codeUrl: "",
        /*系统开发配置信息*/
        opennessConfig:{
          /*安卓app下载二维码base64*/
          androidAppDownloadQrCode:"",
          webSystemLatestVersion: '' // pc版本号
        },
        cookiePassword: "",
        isShowApp: false,
        loginForm: {
          username: "",
          password: "",
          rememberMe: false,
          code: "",
          uuid: ""
        },
        loginRules: {
          username: [
            { required: true, trigger: "blur", message: "用户名不能为空" }
          ],
          password: [
            { required: true, trigger: "blur", message: "密码不能为空" }
          ],
          code: [{ required: true, trigger: "change", message: "验证码不能为空" }]
        },
        loading: false,
        redirect: undefined
      };
    },
    watch: {
      $route: {
        handler: function(route) {
          this.redirect = route.query && route.query.redirect;
        },
        immediate: true
      }
    },
    computed: {
      submitBtn() {
        const { username, password } = this.loginForm
        if (username.trim().length > 0 && password.trim().length > 0) {
          return 'submit-btn'
        } else {
          return 'submit-btn disabled'
        }
      }
    },
    created() {
      //this.getCode();
      this.getCookie();
      this.getOpennessConfig();
      Cookies.remove("Ip-Admin-Token")
      // this.particale()
    },
    mounted() {
      // this.particale()
    },
    methods: {
      appClick() {
        this.isShowApp = true
      },
      particale() {
        var config = {
          num: 360,//数量
          size: {
            minSize: 1,//最小尺寸
            maxSize: 40//最大尺寸
          },
          zone: {
            x: [0, 1],
            y: [0, 1]
          },
          speed     : {
            x : [-1, 1],//x轴速度区间
            y : [-1, 1],//y轴速度区间
            ax: [-0.01, 0.01],//x轴加速度区间
            ay: [-0.01, 0.01]//y轴加速度区间
          },
          time: {
            fadeIn : 500,//生成时间
            fadeOut: 4000//消逝时间
          },
          atmosphere: [//色彩氛围，在指定颜色区间中随机生成颜色效果真是棒极了！
            {
              start:{
                // r:192,
                // g:128,
                // b:32,
                // a:0.3
                r:20,
                g:43,
                b:223,
                a:0.3
              },
              end:{
                // r:255,
                // g:192,
                // b:128,
                // a:0.2
                r:175,
                g:20,
                b:223,
                a:0.5
              }
            },
            {
              start:{
                r:24,
                g:139,
                b:220,
                a:0.3
              },
              end:{
                r:25,
                g:47,
                b:213,
                a:0.5
              }
            }
          ],
          background: "rgba(47,26,203,0.8)",
          follow: false,
          active: 0,//鼠标随动程度
          blur: false//模糊效果……存在性能问题但不舍得抛弃_(:3」∠)_
        }
        let ml = mapleParticles(document.getElementById("login-loading"), config)
      },
      // 机构注册
      registryHandle() {
        this.$emit('componentChange', 'register')
      },
      // 忘记密码
      forgetPas() {
        // window.open('/findPwd', '_blank')
        this.$emit('componentChange', 'findPwd')
      },
      getCode() {
        getCodeImg().then(res => {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        });
      },
      getCookie() {
        const username = Cookies.get("username");
        const password = Cookies.get("password");
        const rememberMe = Cookies.get('rememberMe')
        this.loginForm = {
          username: username === undefined ? this.loginForm.username : username,
          password: password === undefined ? this.loginForm.password : decrypt(password),
          rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
        };
      },
      handleLogin() {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true;
            if (this.loginForm.rememberMe) {
              Cookies.set("username", this.loginForm.username, { expires: 30 });
              Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
              Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
            } else {
              Cookies.remove("username");
              Cookies.remove("password");
              Cookies.remove('rememberMe');
            }
            this.$store.dispatch("Login", this.loginForm).then(() => {
              this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
            }).catch(() => {
              this.loading = false;
              this.getCode();
            });
          }
        });
      },
      /*获取系统开放配置信息*/
      getOpennessConfig(){
        opennessConfigList().then(res=>{
          this.opennessConfig=res.data
        })
      },
      goToLink() {
        const { href } = this.$router.resolve({
          path: "/serviceAgreement",
          // params: { id: 0 }
        })
        window.open(href, '_blank')
      },
      toServiceIntro() {
        this.$router.push('/serviceIntro')
      }
    }
  };
  </script>

  <style rel="stylesheet/scss" lang="scss" scoped>
  .baNum:hover{
    text-decoration:underline;
  }
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 100%;
    background-image: url("../../../assets/images/login-back.png");
    //background-size: cover;
    position: relative;
    background-size: 100% 100%;
    //position: relative;
    position: fixed;
    width: 100%;
    height: 100%;
    /* z-index: 2; */
  }
  .loginLeftTop{
    position: absolute;
    top:55px;
    left: 83px;
  }
  .title {
    margin: 0px auto;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 500;
    color: #2B60F8;
    text-shadow: 1px 1px 2px rgba(53, 98, 208, 0.5);
  }

  .login-form {
    margin-left: 40%;
    background: #F0F4FF;
    border: 9px solid rgba(103, 144, 246, 0.58);
    box-shadow: 0px 0px 20px 0px rgba(4, 8, 17, 0.8);
    border-radius: 8px;
    width: 400px;
    padding: 25px 25px 5px 25px;
    /* z-index: 2; */
    .submit-btn {
      width:100%;
      border-color: inherit !important;
      border-radius: 4px;
      border: 1px solid #DCDFE6 !important;
			background: linear-gradient(90deg, #00AAFF, #2B60F8);
      &.disabled {
        background: linear-gradient(90deg, #00AAFF, #2B60F8);
        box-shadow: 0px 4px 8px 0px rgba(40, 128, 215, 0.5);
        opacity: 0.5;
        border: none;
      }
    }
    .el-input {
      height: 38px;
      input {
        height: 38px;
      }
    }
    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }
  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }
  .login-code {
    width: 33%;
    height: 38px;
    float: right;
    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .el-login-footer {
    height: 40px;
    line-height: 40px;
    width: 100%;
    height: 40px;
    background: #0F174D;
    opacity: 0.8;
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 14px;
    letter-spacing: 1px;
    div {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .login-code-img {
    height: 38px;
  }
  .login-right-fixed {
    position: fixed;
    right: 10px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    .fixed-item {
      width: 64px;
      height: 64px;
      background: #3380FF;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 3px;
      position: relative;
      .icon-tag {
        display: block;
        font-size: 20px;
        margin-bottom: 5px;
      }
      &:hover {
        cursor: pointer;
        background-color: #3350FF;
      }
    }
  }
  /* @import '~@/assets/styles/par.css' */
  </style>
