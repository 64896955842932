var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about-login-form-wrap" },
    [
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "editPwdForm",
          staticClass: "editPwd-form",
          attrs: {
            model: _vm.formObj,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "oldPassword", label: "旧密码" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.formObj.oldPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.formObj, "oldPassword", $$v)
                  },
                  expression: "formObj.oldPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "newPassword", label: "新密码" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.formObj.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.formObj, "newPassword", $$v)
                  },
                  expression: "formObj.newPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checkPass", label: "确认密码" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.formObj.checkPass,
                  callback: function ($$v) {
                    _vm.$set(_vm.formObj, "checkPass", $$v)
                  },
                  expression: "formObj.checkPass",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  class: _vm.submitBtn,
                  attrs: { type: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "title-header clearfix" }, [
      _c("h3", { staticClass: "fl" }, [_vm._v("修改密码")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }