var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: { model: _vm.loginForm, rules: _vm.loginRules },
        },
        [
          _c(
            "h1",
            { staticClass: "title", staticStyle: { "font-size": "26px" } },
            [_vm._v("药数矩阵")]
          ),
          _c(
            "h3",
            {
              staticClass: "title",
              staticStyle: { "font-size": "14px", color: "#2B60F8" },
            },
            [_vm._v("PDBox")]
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "30px" },
              attrs: { prop: "username" },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "text",
                    "auto-complete": "off",
                    placeholder: "用户名/手机号",
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "user" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    "show-password": "",
                    type: "password",
                    "auto-complete": "off",
                    placeholder: "密码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin($event)
                    },
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "password" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { display: "none" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "63%" },
                  attrs: { "auto-complete": "off", placeholder: "验证码" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin($event)
                    },
                  },
                  model: {
                    value: _vm.loginForm.code,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "code", $$v)
                    },
                    expression: "loginForm.code",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "validCode" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "login-code" }, [
                _c("img", {
                  staticClass: "login-code-img",
                  attrs: { src: _vm.codeUrl },
                  on: { click: _vm.getCode },
                }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
              },
            },
            [
              _c(
                "div",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.loginForm.rememberMe,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "rememberMe", $$v)
                        },
                        expression: "loginForm.rememberMe",
                      },
                    },
                    [_vm._v("记住密码")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.forgetPas } },
                    [_vm._v("忘记密码？")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-button",
                {
                  class: _vm.submitBtn,
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("登 录")])
                    : _c("span", [_vm._v("登 录 中...")]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-popover",
                {
                  ref: "popover",
                  attrs: {
                    placement: "bottom",
                    width: "340",
                    trigger: "click",
                  },
                },
                [
                  _c("div", [
                    _c("p", [
                      _vm._v("若无账号，请先进行机构注册申请，注册成功后，"),
                    ]),
                    _c("p", [_vm._v("账号将通过短信及电子邮箱发送给您！")]),
                  ]),
                ]
              ),
              _c("i", {
                directives: [
                  {
                    name: "popover",
                    rawName: "v-popover:popover",
                    arg: "popover",
                  },
                ],
                staticClass: "el-icon-info",
                staticStyle: { color: "#2B60F8", cursor: "pointer" },
              }),
              _c(
                "el-link",
                {
                  staticStyle: { color: "#2B60F8", "margin-left": "10px" },
                  on: { click: _vm.registryHandle },
                },
                [_vm._v("机构注册")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("section", { staticClass: "login-right-fixed" }, [
        _c(
          "div",
          {
            staticClass: "fixed-item app-logo",
            attrs: { id: "app-logo" },
            on: {
              mouseenter: function ($event) {
                _vm.isShowApp = true
              },
              mouseleave: function ($event) {
                _vm.isShowApp = false
              },
            },
          },
          [
            _c("svg-icon", {
              staticClass: "icon-tag",
              attrs: { "icon-class": "app-logo" },
            }),
            _c("span", [_vm._v("微信小程序")]),
            _c("el-collapse-transition", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowApp,
                      expression: "isShowApp",
                    },
                  ],
                  staticClass: "app-qrcode-wrap",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "transition-box",
                      staticStyle: {
                        background: "#fff",
                        position: "absolute",
                        left: "-120px",
                        width: "120px",
                        height: "120px",
                        padding: "10px",
                        top: "0",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "80%",
                          height: "80%",
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        },
                        attrs: {
                          src: _vm.opennessConfig.androidAppDownloadQrCode,
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "fixed-item", attrs: { id: "official-accounts" } },
          [
            _c("svg-icon", {
              staticClass: "icon-tag",
              attrs: { "icon-class": "account-logo" },
            }),
            _c("span", [_vm._v("公众号")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "fixed-item", attrs: { id: "help-document" } },
          [
            _c(
              "a",
              {
                staticClass: "item",
                attrs: {
                  "data-v-7ed19c32": "",
                  href: "/PDBox-药数矩阵.pdf",
                  target: "_blank",
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "icon-tag",
                  staticStyle: { margin: "0px auto 5px" },
                  attrs: { "icon-class": "document-logo" },
                }),
                _c("span", [_vm._v("帮助文档")]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "fixed-item",
            attrs: { id: "contact-us" },
            on: { click: _vm.toServiceIntro },
          },
          [
            _c("svg-icon", {
              staticClass: "icon-tag",
              attrs: { "icon-class": "service-intro", fill: "#fff" },
            }),
            _c("span", [_vm._v("服务介绍")]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "el-login-footer" }, [
        _c("div", [
          _c("img", {
            staticStyle: {
              width: "20px",
              height: "20px",
              "margin-top": "10px",
              float: "left",
            },
            attrs: { src: require("../../../assets/images/icon.png"), alt: "" },
          }),
          _c("span", { staticStyle: { "padding-left": "5px" } }, [
            _vm._v(_vm._s(_vm.opennessConfig.webSystemLatestVersion)),
          ]),
        ]),
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _vm._m(5),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loginLeftTop" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/images/pdBox-login.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: {
          width: "20px",
          height: "20px",
          "margin-top": "10px",
          float: "left",
        },
        attrs: {
          src: require("../../../assets/images/beian-icon.png"),
          alt: "",
        },
      }),
      _c(
        "a",
        {
          staticClass: "baNum",
          staticStyle: { display: "inline-block", "text-decoration": "none" },
          attrs: {
            target: "_blank",
            href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620",
          },
        },
        [
          _c("span", { staticStyle: { "padding-left": "5px" } }, [
            _vm._v("沪公网安备 31010602006620号"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass: "baNum",
          staticStyle: { padding: "0" },
          attrs: { href: "https://beian.miit.gov.cn", target: "_blank" },
        },
        [_vm._v("沪ICP备20018067号-2")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "span",
        { staticStyle: { "font-size": "18px", "vertical-align": "middle" } },
        [_vm._v("©")]
      ),
      _vm._v("2022 上海卫心科技有限公司"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass: "baNum",
          attrs: { href: "/serviceAgreement.html", target: "_blank" },
        },
        [_vm._v("服务协议")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass: "baNum",
          attrs: { href: "/privacyPolicy.html", target: "_blank" },
        },
        [_vm._v("隐私政策")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }