import { render, staticRenderFns } from "./EditPwdForm.vue?vue&type=template&id=19ca79c4&scoped=true&"
import script from "./EditPwdForm.vue?vue&type=script&lang=js&"
export * from "./EditPwdForm.vue?vue&type=script&lang=js&"
import style0 from "./EditPwdForm.vue?vue&type=style&index=0&id=19ca79c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ca79c4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/macos/workspaces/winning2024/pdf-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19ca79c4')) {
      api.createRecord('19ca79c4', component.options)
    } else {
      api.reload('19ca79c4', component.options)
    }
    module.hot.accept("./EditPwdForm.vue?vue&type=template&id=19ca79c4&scoped=true&", function () {
      api.rerender('19ca79c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/login/components/EditPwdForm.vue"
export default component.exports